$pp-iphone-portrait: 320px;

.device-mobile {
  #shipping_container {
    #delivery-options {
      margin-bottom: 0;
      #delivery-options-group {
        padding: 0 20px;
        .radio-wrapper {
          float: left;
          margin-bottom: 20px;
          margin-right: 10px;
          width: 45%;
          input {
            margin-right: 5px;
          }
          label {
            font-weight: bold;
            margin-bottom: 10px;
            margin-left: 5px;
          }
          .opt_a {
            display: block;
            margin-left: 23px;
          }
          .opt_desc {
            display: block;
            font-weight: normal;
            margin-left: 23px;
            width: 100%;
          }
        }
      }
    }
    .shipping-address-info {
      margin-bottom: 20px;
      width: 100%;
      .left_col {
        padding-top: 0;
        .sub_form {
          margin-top: 0;
          .radio {
            margin: 10px 0 5px;
          }
          #choose-address {
            select {
              margin-top: 10px;
              width: 100%;
            }
          }
        }
      }
    }
    .billing_method_section {
      padding: 0 10px;
    }
    .email_promotions {
      margin: 15px 0;
      padding: 0 20px;
    }
    .click-and-collect {
      border: none;
      margin-bottom: 20px;
      padding: 0 10px;
      width: 100%;
      .personal-details {
        .form_element {
          position: relative;
          width: 100%;
          .required {
            display: block;
            float: left;
          }
          label {
            color: inherit;
            font-family: inherit;
            margin-right: 10px;
            margin-bottom: 5px;
            max-width: 90%;
            text-transform: inherit;
            width: 89%;
          }
          input,
          select {
            padding: 4px 0;
            margin: 8px 5px 10px 0;
            text-indent: 8px;
            height: 40px;
            font-size: 15px;
            width: 100%;
          }
          &.country {
            padding: 8px 5px 10px 0;
          }
        }
      }
      .local-collection {
        .show-collection-point {
          margin-top: 10px;
          h5 {
            color: #000;
            font-size: 15px;
            font-weight: bold;
            .required_mark {
              color: #000;
            }
            .local-collection-link {
              font-size: 12px;
              @if $cr19 == true {
                color: $cr19-text-black;
              } @else {
                color: #5dba98;
              }
            }
          }
        }
        .form_element {
          input {
            padding: 4px 0;
            margin: 8px 5px 10px 0;
            text-indent: 8px;
            height: 40px;
            font-size: 15px;
            width: 100%;
          }
          a {
            border: 0;
            color: white;
            display: inline-block;
            font-size: 0.9em;
            letter-spacing: 0;
            line-height: 1.5;
            margin: 15px 0 0;
            padding: 4px 10px 3px;
            text-align: center;
            text-transform: uppercase;
            zoom: 1;
            @if $cr19 == true {
              background-color: $cr19-bg-black;
            } @else {
              background-color: #5dba98;
            }
          }
          a:hover {
            background: #44a07e none repeat scroll 0 0;
            text-decoration: none;
          }
          b {
            font-weight: bold;
          }
        }
      }
    }
  }
  &#index {
    .click_and_collect_map {
      .close-container {
        display: none;
      }
      .overlay-content {
        height: 100%;
        position: relative;
        width: 100%;
        .map-container {
          display: block;
          height: 100%;
          position: relative;
          overflow: auto;
          .address-map-location-panel {
            display: block;
            //bottom: 0;
            height: auto;
            left: 0;
            margin: 0;
            overflow: hidden;
            position: relative;
            top: 9999px;
            //-webkit-transition: left .5s;
            //transition: left 0.5s ease 0s;
            //width: 100%;
            width: 265px;
            z-index: 2;
            display: none;
            &.active {
              position: absolute;
              height: 100%;
              left: 0;
              top: 202px;
              margin: 55px 0 0 0;
              width: 100%;
              header {
                h2 {
                  display: none;
                }
              }
            }
            .messages {
              display: none;
            }
            .location {
              overflow: hidden;
              padding: 0 0.5em 1em;
              margin: 0 0 1em;
              border-bottom: 1px solid #ccc;
              color: black;
              -webkit-transition: padding 0.5s, background 0.5s;
              transition: padding 0.5s, background 0.5s;
              &:first-child {
                padding-top: 50px;
              }
              .group_location_radio {
                float: left;
                width: 25px;
              }
              .group_location_list {
                margin-left: 30px;
                .address {
                  font-size: 12px;
                }
              }
              div.button {
                clear: both;
                margin-top: 1em;
              }
              .button {
                .select-access-point {
                  background-color: #fafafa;
                  cursor: pointer;
                  font-size: 0.9em;
                  height: auto;
                  line-height: normal;
                  margin: 10px 0 0 5px;
                  padding: 5px 10px;
                  text-align: center;
                  text-decoration: none !important;
                  width: auto;
                  @if $cr19 == true {
                    border: 1px solid $cr19-border-black;
                    color: $cr19-text-black;
                  } @else {
                    border: 1px solid #5dba98;
                    color: #5dba98;
                  }
                }
              }
              p {
                margin: 0 0 0.5em;
              }
            }
            .locations {
              cursor: pointer;
              height: 100%;
              overflow-y: scroll;
              background-color: #ffffff;
            }
          }
          .search-box-cancel {
            border: 1px solid #6e6e6e;
            cursor: pointer;
            height: 18px;
            margin-top: 5px;
            padding: 1px 0 0;
            position: absolute;
            right: 5px;
            text-align: center;
            top: 0;
            width: 18px;
            z-index: 1;
            background: transparent url(/media/export/images/checkout/click_collect/map_close.jpg) no-repeat center;
            span::before {
              color: #000;
              display: block;
              font-size: 12px;
              padding-top: 0;
              text-decoration: none;
            }
          }
          .map-wrapper {
            display: inline-block;
            height: 100%;
            margin-top: 0;
            //overflow: hidden;
            position: relative;
            vertical-align: top;
            width: 100%;
            .address-map-options-search-panel {
              left: 0;
              margin: 35px 20px 10px;
              position: relative;
              top: 0;
              .options-search-box {
                select {
                  background-color: #fafafa;
                  background: url(/media/export/cms/images/icon_metro.png);
                  background-repeat: no-repeat;
                  background-position: 100%;
                  border: 1px solid #040a2b;
                  color: #5a5a5a;
                  cursor: pointer;
                  height: 31px;
                  margin: 0 0 10px;
                  -moz-appearance: none;
                  -webkit-appearance: none;
                  padding: 5px;
                  text-align: center;
                  width: 100%;
                  &:first-child {
                    background: url(/media/export/cms/images/icon_city.png);
                    background-repeat: no-repeat;
                    background-position: 100%;
                  }
                }
                .address-map-search-panel {
                  border-radius: 0;
                  font-size: 1.4em;
                  margin: 0;
                  position: relative;
                  top: 0 !important;
                  z-index: 1;
                  width: auto !important;
                  .search-box-wrapper {
                    clear: both;
                    float: none;
                    padding-left: 0;
                    width: 100%;
                    .search-box {
                      overflow: hidden;
                    }
                  }
                  .search-box-field {
                    border-radius: 0;
                    border: 1px solid #040a2b;
                    color: #5a5a5a;
                    font-size: 0.7em;
                    font-weight: normal;
                    float: left;
                    height: 31px;
                    margin: 0;
                    padding: 0 39px 0 10px;
                    width: 85%;
                  }
                  .search-box-submit {
                    background-color: #f2f2f2;
                    background-image: url(/media/export/cms/images/icon_search.png);
                    background-repeat: no-repeat;
                    background-position: 50%;
                    border: 1px solid #040a2b;
                    height: 33px;
                    float: none !important;
                    margin-right: 0;
                    padding: 0;
                    position: absolute;
                    right: 0;
                    text-align: left;
                    top: 0;
                    width: 39px;
                    span {
                      display: none;
                    }
                    span::before {
                      content: none;
                      display: block;
                      font-family: 'arial';
                      font-size: 16px;
                      margin-top: -2px;
                      text-decoration: none;
                    }
                  }
                  .status-bar-wrapper {
                    bottom: 20px;
                    left: 50%;
                    margin-left: -15em;
                    position: fixed;
                    text-align: center;
                    width: 30em;
                    z-index: 2;
                    .status-bar {
                      .message {
                        padding: 0.5em;
                        border: none;
                        box-shadow: 0px 1px 4px #333;
                        &:first-child {
                          border-radius: 0;
                        }
                        &:last-child {
                          border-radius: 0;
                        }
                        .close {
                          float: right;
                          a {
                            text-decoration: none;
                            color: inherit;
                          }
                        }
                        &.error {
                          background: #fed2024;
                          color: white;
                        }
                        &.warning {
                          background: #f6e914;
                          color: black;
                        }
                        &.success {
                          background: white;
                          border-color: black;
                          color: black;
                        }
                        &.information {
                          background: black;
                          color: white;
                        }
                      }
                    }
                  }
                }
              }
            }
            .maps {
              height: 100%;
              margin: 0;
              position: relative;
              width: 100%;
              .map {
                .map-window-info {
                  .title {
                    h3 {
                      color: #666666;
                      font-size: 18px;
                    }
                  }
                  .address {
                    color: #8f8f8f;
                    font-size: 13px;
                    line-height: 18px;
                    p {
                      margin-top: 0;
                    }
                  }
                  a.button {
                    font-size: 13px;
                    height: auto;
                    line-height: 19px;
                    margin-bottom: 5px;
                    margin-top: 4px;
                    padding: 5px 10px;
                    width: 60%;
                  }
                  div.button {
                    a {
                      background-color: #fafafa;
                      cursor: pointer;
                      width: 35%;
                      @if $cr19 == true {
                        border: 1px solid $cr19-border-black;
                        color: $cr19-text-black;
                      } @else {
                        border: 1px solid #5dba98;
                        color: #5dba98;
                      }
                    }
                  }
                }
              }
              .maps-toggle-container {
                margin: 10px 0;
                position: relative;
                text-align: right;
                z-index: 2;
                span {
                  background-color: #ffffff;
                  border: 1px solid #000000;
                  border-radius: 1px;
                  color: #000000;
                  display: inline-block;
                  line-height: normal;
                  margin: 0 1px;
                  padding: 8px 10px;
                  font-size: 0.9em;
                }
                span.selected {
                  background-color: #000000;
                  border: 1px solid #000000;
                  color: #ffffff;
                }
              }
            }
            .metro-map {
              .popup_container {
                position: absolute;
                padding: 14px 14px 9px;
                background-color: #f1f1f1;
                border: 1px solid #5d5e5c;
                border-radius: 6px;
                min-width: 275px;
                min-height: 225px;
                z-index: 101;
                top: 50px;
                .location {
                  .statuspoint {
                    border-radius: 5px;
                    border: 1px solid #000;
                    width: 9px;
                    height: 9px;
                    position: absolute;
                    right: 0px;
                    top: 28px;
                  }
                  .title {
                    h3 {
                      color: #666666;
                      font-size: 18px;
                      margin-bottom: 0;
                    }
                  }
                  .address {
                    color: #8f8f8f;
                    font-size: 13px;
                    line-height: 18px;
                    p {
                      margin-top: 0;
                    }
                  }
                  a.button {
                    font-size: 14px;
                    height: 20%;
                    margin-bottom: 0;
                    margin-top: 10px;
                    width: 65%;
                  }
                  div.button {
                    a {
                      background-color: #fafafa;
                      cursor: pointer;
                      padding: 5px 10px;
                      width: auto;
                      @if $cr19 == true {
                        border: 1px solid $cr19-border-black;
                        color: $cr19-text-black;
                      } @else {
                        border: 1px solid #5dba98;
                        color: #5dba98;
                      }
                    }
                  }
                }
                .close {
                  position: absolute;
                  right: 5px;
                  top: 5px;
                  text-decoration: none;
                  font-size: 14px;
                  font-weight: bold;
                  color: #666;
                  z-index: 102;
                  span {
                    &:before {
                      content: 'X';
                    }
                  }
                }
                .atop {
                  position: absolute;
                  top: -12px;
                  left: 50%;
                  margin-left: -12px;
                  display: block;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 0 12px 12px 12px;
                  border-color: transparent transparent #232323 transparent;
                  &:after {
                    position: absolute;
                    display: block;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 11px 11px 11px;
                    border-color: transparent transparent #f1f1f1 transparent;
                    content: '';
                    top: 1px;
                    left: -11px;
                  }
                }
                .abot {
                  position: absolute;
                  bottom: -12px;
                  left: 50%;
                  margin-left: -12px;
                  display: block;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 12px 12px 0 12px;
                  border-color: #232323 transparent transparent transparent;
                  &:after {
                    position: absolute;
                    display: block;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 11px 11px 0 11px;
                    border-color: #f1f1f1 transparent transparent transparent;
                    content: '';
                    top: -12px;
                    left: -11px;
                  }
                }
                .itemspopuplist_one {
                  height: 280px;
                  width: 290px;
                  .location_wrapper {
                    height: 260px;
                    width: 282px;
                  }
                }
                .itemspopuplist_two {
                  height: 280px;
                  width: 570px;
                  .location_wrapper {
                    height: 255px;
                    margin-bottom: 20px;
                    width: 265px;
                  }
                }
                .andmore {
                  height: 280px;
                }
                .location_wrapper {
                  display: inline-block;
                  margin-bottom: 10px;
                  margin-right: 20px;
                  position: relative;
                  overflow: hidden;
                  text-align: initial;
                  .map-window-info {
                    .title {
                      h3 {
                        font-size: 20px;
                      }
                    }
                    .address {
                      font-size: 19px;
                      margin-bottom: 5px;
                    }
                    .more-info {
                      bottom: 65px;
                      padding: 5px 10px;
                      position: absolute;
                      width: auto;
                    }
                    a.button {
                      font-size: 19px;
                    }
                    div.button {
                      a {
                        bottom: 0;
                        position: absolute;
                      }
                    }
                  }
                }
                .mCustomScrollBox {
                  position: absolute;
                  overflow: hidden;
                  height: 87%;
                  max-width: 100%;
                  outline: none;
                  direction: ltr;
                  left: 0px;
                  overflow: auto;
                  padding: 0px 0px 0 16px;
                }
              }
            }
            .metro-map {
              background-color: #fff;
              float: right;
              height: 100%;
              width: 100%;
            }
            #metromap {
              height: 100%;
              width: 100%;
              position: relative;
              overflow: hidden;
              .points,
              .customzoom {
                position: absolute;
                display: none;
              }
              .points {
                div.point_metro_img {
                  position: absolute;
                  .metro_image {
                    max-width: none;
                  }
                }
              }
              .metroPoint {
                position: absolute;
                width: 20px;
                height: 20px;
                background: url(//pickpoint.ru/select/design/metro_marker2.png) center center no-repeat;
                z-index: 1;
              }
            }
          }
          .map-title {
            border-bottom: 1px solid #333;
            border-top: 1px solid #333;
            font-size: 12px;
            font-weight: bold;
            margin: 0;
            padding: 20px;
            text-align: center;
          }
          .map {
            height: 100%;
            width: 100%;
            &.hidden {
              display: none !important;
            }
          }
          .locations {
            .messages {
              display: none;
              margin-top: 90px;
            }
            .location-info-windows {
              display: block;
            }
            .metro-list {
              display: block;
              padding: 10px 20px 10px 10px;
              .metro-info {
                padding: 3px 0;
                .metro_list_radio {
                  float: left;
                  width: 25px;
                }
                .metro-qty {
                  float: right;
                  margin-top: 0;
                }
              }
            }
            &.metro-on {
              .location-info-windows {
                display: none;
              }
            }
          }
          .map-window-more-info {
            background: #fff;
            border: 1px solid #dbdbdb;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 202;
            margin-top: 30px;
            .inner {
              position: relative;
              overflow: hidden;
              .close {
                cursor: pointer;
                font-size: 23px;
                font-style: normal;
                font-weight: normal;
                padding: 10px;
                position: absolute;
                right: 0;
                top: 0;
                span::before {
                  color: #000;
                  content: 'X';
                  display: block;
                  font-size: 12px;
                  padding-top: 0;
                  text-decoration: none;
                }
              }
            }
            .content {
              max-height: 595px;
              margin-top: 35px;
              padding: 0 20px 20px;
              overflow: auto;
              div {
                margin-bottom: 6px;
              }
              h3 {
                font-size: inherit;
              }
              .hint_green {
                background: #71a751;
                color: #ffffff;
              }
              .hint {
                border-radius: 5px;
                display: inline-block;
                margin-bottom: 10px;
                padding: 4px 14px;
              }
              .image {
                img {
                  display: block;
                  margin: 0 auto;
                }
              }
              p {
                margin-bottom: 0;
              }
              a.button {
                background-color: #fafafa;
                font-size: 13px;
                width: 35%;
                @if $cr19 == true {
                  border: 1px solid $cr19-border-black;
                  color: $cr19-text-black;
                } @else {
                  border: 1px solid #5dba98;
                  color: #5dba98;
                }
              }
            }
          }
        }
      }
    }
  }
  #map-foreground-node {
    background-color: #ffffff;
    border-color: #666666;
    padding: 0;
    position: fixed;
    top: 0;
  }
  #map-overlay-background {
    left: 0;
    width: 100%;
    top: 0;
    bottom: 0;
    display: block;
    background-color: #000;
    opacity: 0.7;
    height: initial !important;
    z-index: 0;
    position: fixed;
  }
}

#review {
  .checkout-review {
    h3 {
      margin-top: 15px;
    }
    .transaction-details {
      .transaction-item {
        margin-bottom: 20px;
        h4 {
          margin-bottom: 5px;
        }
        a.grey_button {
          display: block;
          margin-bottom: 5px;
        }
        b {
          font-weight: bold;
        }
      }
    }
  }
}

#order_detail {
  #content {
    .transaction-details {
      .transaction-item {
        b {
          font-weight: bold;
        }
      }
    }
  }
}
