@import '../theme-bootstrap';

.elc-product-quick-view-wrapper {
  .elc-product-quick-view-details-wrapper .elc-add-to-bag-sku-inventory-status-message,
  .elc-right-column-bottom-cta-section .elc-add-to-bag-sku-inventory-status-message {
    align-self: flex-start;
    order: 3;
    max-width: 350px;
    min-height: fit-content;
    margin: 0 0 24px;
    text-align: #{$rdirection};
  }
}
.elc-grid-container {
  .elc-product-short-description-wrapper {
    font-weight: normal;
  }
}
