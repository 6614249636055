html,
input,
select,
textarea {
  font-family: Roboto, Verdana, sans-serif !important;
}
$base-light-font-family: Roboto, Verdana, sans-serif;
$base-font-family: Roboto, Verdana, sans-serif;
$base-font-family-roman: Roboto, Verdana, sans-serif;
$base-italic-font-family: Roboto, Verdana, sans-serif;
$base-black-font-family: Roboto, Verdana, sans-serif;
$base-bold-font-family: Roboto, Verdana, sans-serif;
$base-link-font-family: Roboto, Verdana, sans-serif;
$base-font-family: Roboto, Verdana, sans-serif;
$base-button-font-family: $base-bold-font-family;
$wink-font-family-1: Roboto, Verdana, sans-serif;
$wink-font-family-2: Roboto, Verdana, sans-serif;
$wink-font-family-3: Roboto, Verdana, sans-serif;

$xsmall-up: 320px;
$small-min: 414px;
$small-max: 736px;

// Colors
$color-loyalty-lvl1: #cadcc2;
$color-loyalty-lvl2: #fcd05a;
$color-loyalty-lvl3: #b09dca;

// Roboto font style
$Roboto: 'Roboto';
$Roboto-bold: 'Roboto-bold';
$robotoCondensedBold: 'roboto-condensed-bold';
$robotoCondensedRegular: 'roboto-condensed-regular';

$ru-fonts-folder: '/sites/clinique/themes/cl_base/fonts';

@font-face {
  font-family: '#{$Roboto}';
  src: url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Condensed-Regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: '#{$Roboto-bold}';
  src: url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Condensed-Bold.woff2') format('woff2');
  font-display: swap;
}

// CPHelvetica font style
$cpHelveticaLight: 'CPHelvetica-Light';
@font-face {
  font-family: '#{$cpHelveticaLight}';
  src: url('#{$netstorage-font-path}cyrilic/CPHelvetica/CPHelvetica-Light.woff2') format('woff2');
  font-display: swap;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-ThinItalic-cyrillic-ext.woff2')
      format('woff2');
  font-display: swap;
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-ThinItalic-cyrillic.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-ThinItalic-greek-ext.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-ThinItalic-greek.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-ThinItalic-vietnamese.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-ThinItalic-latin-ext.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-ThinItalic-latin.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-LightItalic-cyrillic-ext.woff2')
      format('woff2');
  font-display: swap;
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-LightItalic-cyrillic.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-LightItalic-greek-ext.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-LightItalic-greek.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-LightItalic-vietnamese.woff2')
      format('woff2');
  font-display: swap;
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-LightItalic-latin-ext.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-LightItalic-latin.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: local('Roboto Italic'), local('Roboto-Italic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Italic-cyrillic-ext.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: local('Roboto Italic'), local('Roboto-Italic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Italic-cyrillic.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: local('Roboto Italic'), local('Roboto-Italic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Italic-greek-ext.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: local('Roboto Italic'), local('Roboto-Italic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Italic-greek.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: local('Roboto Italic'), local('Roboto-Italic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Italic-vietnamese.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: local('Roboto Italic'), local('Roboto-Italic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Italic-latin-ext.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: local('Roboto Italic'), local('Roboto-Italic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Italic-latin.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-MediumItalic-cyrillic-ext.woff2')
      format('woff2');
  font-display: swap;
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-MediumItalic-cyrillic.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-MediumItalic-greek-ext.woff2')
      format('woff2');
  font-display: swap;
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-MediumItalic-greek.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-MediumItalic-vietnamese.woff2')
      format('woff2');
  font-display: swap;
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-MediumItalic-latin-ext.woff2')
      format('woff2');
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/oboto-MediumItalic-latin.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-BoldItalic-cyrillic-ext.woff2')
      format('woff2');
  font-display: swap;
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-BoldItalic-cyrillic.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-BoldItalic-greek-ext.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-BoldItalic-greek.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-BoldItalic-vietnamese.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-BoldItalic-latin-ext.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-BoldItalic-latin.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  src: local('Roboto Black Italic'), local('Roboto-BlackItalic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-BlackItalic-cyrillic-ext.woff2')
      format('woff2');
  font-display: swap;
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  src: local('Roboto Black Italic'), local('Roboto-BlackItalic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-BlackItalic-cyrillic.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  src: local('Roboto Black Italic'), local('Roboto-BlackItalic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-BlackItalic-greek-ext.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  src: local('Roboto Black Italic'), local('Roboto-BlackItalic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-BlackItalic-greek.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  src: local('Roboto Black Italic'), local('Roboto-BlackItalic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-BlackItalic-vietnamese.woff2')
      format('woff2');
  font-display: swap;
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  src: local('Roboto Black Italic'), local('Roboto-BlackItalic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-BlackItalic-latin-ext.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  src: local('Roboto Black Italic'), local('Roboto-BlackItalic'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-BlackItalic-latin.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local('Roboto Thin'), local('Roboto-Thin'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Thin-cyrillic-ext.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local('Roboto Thin'), local('Roboto-Thin'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Thin-cyrillic.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local('Roboto Thin'), local('Roboto-Thin'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Thin-greek-ext.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local('Roboto Thin'), local('Roboto-Thin'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Thin-greek.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local('Roboto Thin'), local('Roboto-Thin'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Thin-vietnamese.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local('Roboto Thin'), local('Roboto-Thin'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Thin-latin-ext.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local('Roboto Thin'), local('Roboto-Thin'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Thin-latin.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Light-cyrillic-ext.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Light-cyrillic.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Light-greek-ext.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Light-greek.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Light-vietnamese.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Light-latin-ext.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Light-latin.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Regular-cyrillic-ext.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Regular-cyrillic.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Regular-greek-ext.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Regular-greek.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Regular-vietnamese.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Regular-latin-ext.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Regular-latin.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Medium-cyrillic-ext.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Medium-cyrillic.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Medium-greek-ext.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Medium-greek.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Medium-vietnamese.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Medium-latin-ext.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Medium-latin.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Bold-cyrillic-ext.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Bold-cyrillic.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Bold-greek-ext.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Bold-greek.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Bold-vietnamese.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Bold-latin-ext.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Bold-latin.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: local('Roboto Black'), local('Roboto-Black'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Black-cyrillic-ext.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: local('Roboto Black'), local('Roboto-Black'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Black-cyrillic.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: local('Roboto Black'), local('Roboto-Black'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Black-greek-ext.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: local('Roboto Black'), local('Roboto-Black'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Black-greek.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: local('Roboto Black'), local('Roboto-Black'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Black-vietnamese.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: local('Roboto Black'), local('Roboto-Black'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Black-latin-ext.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: local('Roboto Black'), local('Roboto-Black'),
    url('#{$netstorage-font-path}cyrilic/Roboto/Roboto-Black-latin.woff2') format('woff2');
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body,
html,
div,
ul,
li,
a,
p,
span,
table,
tr,
th,
td,
h1,
h2,
h3,
h4,
h5,
input,
form,
dl,
dt,
dd,
label,
small,
select,
article,
strong,
select,
textarea {
  font-family: $Roboto, sans-serif !important;
}

.gnav-block {
  .text-link--style-2-bold,
  a.text-link--style-2-bold,
  .header-gnav-section__panel-link--header span {
    font-weight: 700;
  }
}
