body {
  &#my_points {
    #loyalty__panel__points .account-loyalty {
      .level.current-level-0 {
        .star-points-benifits {
          display: none;
        }
      }
    }
  }
  .account-loyalty {
    .section-content {
      .loyalty__panel__points__bg__slide-container {
        .loyalty__panel__points__bg__Bar-container {
          width: 95%;
        }
        .loyalty__panel__points__bg__Bar-container .arrow-down {
          &.level-1 {
            border-top-color: $color-loyalty-lvl1;
          }
          &.level-2 {
            border-top-color: $color-loyalty-lvl2;
          }
          &.level-3 {
            border-top-color: $color-loyalty-lvl3;
          }
        }
      }
    }
    .loyalty__panel__points__bg__slide1 {
      .loyalty__panel__points__bg__slide__smart {
        &.level-1 {
          background-color: $color-loyalty-lvl1;
        }
        &.level-2 {
          background-color: $color-loyalty-lvl2;
        }
        &.level-3 {
          background-color: $color-loyalty-lvl3;
        }
      }
    }
  }
  &.section-loyalty {
    .full-width-2-row-v1 {
      padding-bottom: 0;
      background-size: cover;
      #loyalty-25-banner {
        text-align: center;
        @media screen and (min-width: $small-m) {
          width: 350px;
        }
        h3 {
          color: $color-black;
        }
        .cta {
          a {
            margin-top: 25px;
            border: 1px solid $color-black;
          }
        }
      }
    }
  }
}
