/* kladr address lookup */

@mixin kladr-lookup {
  .form-item,
  .form_element {
    position: relative;
    .loading-container {
      position: absolute;
      top: 8px;
      #{$ldirection}: 45%;
    }
    .icon-search {
      position: absolute;
      #{$rdirection}: 5px;
      top: 9px;
      color: $color-dark-grey;
      font-size: 17px;
    }
    &.address1_container {
      .icon-search {
        #{$rdirection}: 17px;
      }
      .valid_marker {
        #{$rdirection}: 31px;
      }
      .invalid_marker {
        #{$rdirection}: 45px;
      }
    }
    .invalid_marker {
      #{$rdirection}: 33px;
    }
    .valid_marker {
      #{$rdirection}: 20px;
    }
    .ac_list {
      background: $color-white;
      li {
        border-top: none;
        padding: 5px 15px;
        &:hover {
          background: $color-grey-light;
        }
        &[code='placeholder'],
        .dropdown-placeholder {
          color: $color-dark-grey;
          cursor: not-allowed;
        }
      }
    }
    input {
      &:read-only {
        background-color: $color-lightest-grey;
      }
    }
    &.postal_code_container {
      .invalid_marker,
      .valid_marker {
        display: none;
      }
    }
  }
}
/* phone-masking */

@mixin phone-masking {
  .intl-tel-input {
    position: relative;
    .hide {
      display: none;
    }
    .flag-dropdown {
      z-index: 1;
      cursor: pointer;
      .selected-flag {
        @include swap_direction(padding, 7px 4px 6px 10px);
        margin: 1px;
        position: absolute;
        top: 0;
        .down-arrow {
          top: 8px;
          position: relative;
          #{$ldirection}: 40px;
          width: 0;
          height: 0;
          border-#{$ldirection}: 4px solid transparent;
          border-#{$rdirection}: 4px solid transparent;
          border-top: 4px solid $color-black;
        }
      }
      .country-list {
        list-style: none;
        padding: 0;
        margin: 0;
        z-index: 1;
        overflow-y: scroll;
        box-shadow: 1px 1px 4px $color-lighter-grey;
        background-color: $color-white;
        border: 1px solid $color-light-grey;
        top: 35px;
        width: 100%;
        position: absolute;
        max-height: 200px;
        .divider {
          padding-bottom: 5px;
          margin-bottom: 5px;
          border-bottom: 1px solid $color-light-grey;
          display: block;
        }
        .country {
          @include swap_direction(padding, 3px 10px);
          line-height: 16px;
          &.highlight {
            background-color: $color-lighter-grey;
          }
        }
        .flag {
          display: inline-block;
          vertical-align: middle;
        }
        .country-name {
          margin-#{$rdirection}: 6px;
        }
      }
    }
    .input_phone_1,
    .error {
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      height: 30px;
      padding-#{$ldirection}: 67px;
      position: relative;
      z-index: 0;
    }
    .invalid_marker,
    .valid_marker {
      z-index: 99;
    }
  }
  .flag-select {
    .flag {
      width: 55px;
      height: 20px;
      background: url('/media/images/checkout/tele_flag.png') no-repeat;
    }
    .by {
      background-position: 0 0;
    }
    .kz {
      background-position: 0px -22px;
    }
    .ru {
      background-position: 0px -44px;
    }
    .em {
      background-position: 0px -66px;
    }
  }
}
