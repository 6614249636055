@import '../theme-bootstrap';

.sd-product-spp {
  .elc-sticky-menu-product-name-price {
    display: none;
    @media #{$cr19-medium-up} {
      display: block;
    }
  }
  .elc-product-full-sticky-menu-row {
    padding: 30px 0;
  }
  .elc-product-full,
  .elc-product-quick-view-wrapper {
    .elc-product-display-name-link {
      font-family: $base-bold-font-family;
      font-weight: 700;
    }
  }
  .elc-product-full {
    .elc-right-column-section {
      max-width: 730px;
      .elc-social-share {
        display: none;
      }
    }
    .elc-product-original-price.elc-price-formatted {
      font-weight: 400;
    }
  }
}

.tiny-waitlist-overlay {
  h2,
  h3 {
    font-size: 22px;
    margin-bottom: 15px;
    line-height: 1;
  }
  .gray {
    font-size: 14px;
  }
  .field {
    margin: 10px 0;
  }
}
