.clinical-reality {
  &--button:hover {
    text-decoration: none;
  }
  &__intro {
    &--analysis {
      font-weight: 500;
      &:hover {
        text-decoration: none;
      }
    }
    &--reality-img {
      @include start-breakpoint($medium-min) {
        padding-top: 40px;
      }
    }
    &--iframe-mobile {
      margin-bottom: 20px;
    }
  }
  &__modules--recommended-products {
    .clinical-reality__recommended-products {
      &--image-container {
        &.clinical-reality__recommended-products-no-eyebrow {
          background: $color-bg-hero-banner;
        }
      }
      &--carousel {
        top: 44%;
      }
    }
    .category-title {
      position: static;
    }
  }
  &__results-intro--title,
  .clinical-reality__module--title,
  .clinical-reality__module--title-copy,
  .small-product-add-to-bag {
    text-transform: none;
  }
}
