/* -----Power Reviews------ */
.mpp-product-v1 {
  .reviews {
    padding: 0px 0px 3px 15%;
  }
}

#pr-review-display {
  .p-w-r {
    .pr-review-display {
      .pr-review {
        .pr-rd-footer {
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                content: 'Да';
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &:before {
                content: 'Нет';
              }
            }
          }
          .pr-modal-content {
            .pr-flag-review-btn {
              margin-bottom: 10px;
            }
          }
        }
      }
      .pr-rd-main-header {
        .pr-rd-review-header-contents {
          &:before {
            content: 'Фильтр от:';
            @include breakpoint($ab-small-down) {
              content: 'Фильтр:';
            }
          }
        }
        .pr-rd-sort {
          background: url('/media/images/stars/down-arrow.png') no-repeat scroll 100% 2px transparent;
        }
      }
      &.pr-rd-display-desktop {
        .pr-rd-sort-group {
          &:before {
            content: 'Сортировать по:';
          }
        }
      }
      .pr-rd-sort-group {
        float: left;
        &:before {
          content: 'Сортировать по:';
        }
      }
    }
    .pr-multiselect {
      .pr-caret-icon {
        width: 19px;
      }
      .pr-multiselect-button {
        padding: 0 23px 0px 1px;
      }
    }
    .pr-review-snapshot-header-intro {
      .pr-headline {
        font-size: 20px;
        line-height: 20px;
      }
    }
  }
}

#pr-write {
  .p-w-r {
    .tag-group {
      .pr-label-control {
        white-space: initial;
      }
    }
  }
}

.write_a_review__container {
  #pr-write {
    .form-group {
      select {
        padding-right: 23px !important;
      }
    }
  }
}
