.spc_enabled #main.single {
  .responsive-container {
    .panel {
      label.social-login__email-opt-in-label {
        @media #{$medium-up} {
          padding-#{$ldirection}: 12px;
        }
      }
    }
  }
  .sign-in-panel {
    input[type='checkbox'].js-facebook_email_opt_in {
      width: 40px;
      @media #{$medium-up} {
        width: auto;
      }
    }
    .signin-social-section {
      .social-login {
        display: block;
      }
    }
    .signin_container {
      .divider {
        display: block;
      }
    }
    .social-login {
      .social-login__container {
        text-align: none;
        .facebook-button {
          color: $color-white;
          background-color: $color-btn-blue;
          border: none;
          height: 49px;
          width: 100%;
          letter-spacing: 0.25px;
          position: absolute;
          display: none;
          @media #{$medium-up} {
            width: 315px;
            margin-#{$ldirection}: 15%;
          }
        }
        .fb_iframe_widget {
          top: 5px;
        }
        .social-login__button {
          width: 300px;
          margin: 0 auto;
          @media #{$medium-up} {
            width: 426px;
          }
        }
        .social-login__terms {
          margin-top: 20px;
          display: block;
        }
      }
      input[type='checkbox'].facebook_email_optin_input {
        @media #{$medium-up} {
          width: 15px;
          height: 33px;
          @media #{$large-up} {
            width: 18px;
          }
        }
      }
      .social-login__email-opt-in-label {
        margin-top: 0;
      }
    }
  }
  .signin_container {
    .facebook-button {
      display: none;
      margin-#{$ldirection}: 6%;
    }
  }
  .checkout-confirmation-page {
    .social-login {
      padding-bottom: 15px;
      .social-login__container {
        text-align: $ldirection;
        padding-#{$rdirection}: 15px;
        .checkout__panel-title {
          text-transform: uppercase;
        }
        .social-login__email_opt_in {
          padding-#{$ldirection}: 0;
          input[type='checkbox'] {
            width: 35px;
          }
        }
      }
      .social-login__button {
        text-align: $ldirection;
        margin-bottom: 15px;
        .facebook-button {
          color: $color-white;
          background-color: $color-btn-blue;
          border: none;
          height: 49px;
          width: 100%;
          letter-spacing: 0.25px;
          position: absolute;
          display: none;
          @media #{$medium-up} {
            width: 315px;
          }
        }
      }
      .fb_iframe_widget {
        top: 5px;
      }
    }
  }
}

.checkout__index-content {
  .checkout__content {
    .sign-in-panel.panel.main {
      .social-login {
        .social-login__email_opt_in {
          display: inline-flex;
        }
        .social-login__button {
          .facebook-logo {
            display: none;
            width: 100%;
          }
        }
      }
    }
  }
}

#registration.device-mobile,
.locale_ru_ru {
  .registration_content {
    .social-info {
      margin-top: 3px;
      .social-info__header {
        padding-top: 10px;
        padding-bottom: 6px;
      }
      .social-info__content {
        padding-top: 3px;
        p {
          margin-bottom: 16px;
        }
      }
      .social-info__connect {
        display: flex;
        margin-bottom: 21px;
        .fb-overlay-social-info {
          display: inline;
        }
      }
      .social-info__lstatus {
        margin-#{$rdirection}: 0;
        letter-spacing: 0;
        font-size: 9px;
        @media #{$medium-up} {
          font-size: 13px;
        }
        img {
          vertical-align: middle;
          padding-#{$rdirection}: 5px;
          height: 20px;
          width: 20px;
          @media #{$medium-up} {
            height: 30px;
            width: 30px;
          }
        }
      }
      .social-info__rstatus {
        display: inline-block;
        line-height: 20px;
        @media #{$medium-up} {
          line-height: 24px;
        }
        margin-#{$ldirection}: 5px;
        .connect-facebook-button {
          display: none;
          height: 30px;
          padding: 3px;
          line-height: 20px;
          font-size: 12px;
          text-align: center;
          background-color: $color-black;
          color: $color-white;
          border: none;
          text-transform: uppercase;
        }
        .fb-overlay {
          display: none;
        }
        #facebook_disconnect {
          .form-submit {
            margin: 0;
            font-size: 12px;
          }
        }
      }
    }
  }
}

.main_container {
  .social-login {
    .facebook-button {
      color: $color-white;
      background-color: $color-btn-blue;
      border: none;
      height: 49px;
      width: 100%;
      letter-spacing: 0.25px;
      position: absolute;
      display: none;
    }
    .fb_iframe_widget {
      top: 5px;
    }
  }
}
/* Gnav Facebook Button */
.menu-item-blocks {
  #gnav_signin {
    .content.signin_container {
      .outer-wrap.inner-block {
        .social-login.gnav {
          display: block;
        }
      }
    }
    .social-login.gnav {
      .social-login__email-opt-in {
        .social-login__email-opt-in-label {
          margin-top: 0;
          padding-#{$ldirection}: 0;
          margin-#{$ldirection}: 10px;
          display: block;
        }
        .field {
          float: $ldirection;
        }
      }
    }
  }
}

#gnav_signin {
  #signin-block {
    .social-login.gnav {
      .social-login__email-opt-in {
        input[type='checkbox'] {
          width: 15px;
        }
      }
    }
  }
}

.signin_container {
  #signin-block {
    .error_messages {
      margin-#{$rdirection}: 45px;
    }
  }
  .social-login {
    display: none;
    .social-login__button {
      .facebook-logo {
        display: none;
        img {
          @media #{$medium-up} {
            margin-#{$ldirection}: 61px;
          }
        }
      }
    }
    .social-login__email-opt-in {
      .facebook_email_optin_input {
        width: 25px;
        @media #{$landscape} {
          width: 22px;
        }
      }
    }
  }
  .signin-social-section {
    .social-login {
      display: block;
      .social-login__divider {
        display: none;
      }
    }
  }
  .signin-section,
  .signin-social-section {
    width: 49%;
    display: inline-block;
    vertical-align: top;
  }
  position: relative;
  .facebook-button {
    display: none;
    color: $color-white;
    background-color: $color-btn-blue;
    border: none;
    height: 49px;
    width: 315px;
    letter-spacing: 0.25px;
    position: absolute;
    margin-#{$ldirection}: 6%;
  }
  .fb_iframe_widget {
    top: 4px;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
    position: relative;
  }
  .social-login__button {
    padding: 5px 0;
  }
  .divider {
    display: block;
    .social-login__divider {
      text-transform: $text-transform-uppercase;
      &:before {
        content: '';
        width: 2px;
        top: 41px;
        #{$ldirection}: 94%;
        display: inline-block;
        position: absolute;
        height: 92%;
        @if $cr19 == true {
          background: $cr19-bg-black;
        } @else {
          background: $color-cl-green;
        }
      }
      .inner {
        background: $color-white;
        margin-top: 0;
        top: 49%;
        #{$ldirection}: 88%;
        position: absolute;
        padding: 4px 5px;
        border-radius: 25px;
        border: 0 solid $color-white;
        letter-spacing: 0.02px;
        font-size: 19px;
        @if $cr19 == true {
          color: $cr19-text-black;
        } @else {
          color: $color-cl-green;
        }
      }
    }
  }
}
/* Account Signin Page */
.locale_de_de .locale_fr_fr {
  .signin-social-section {
    display: none;
  }
  .responsive-container.checkout {
    .social-login {
      .social-login__container {
        .social-login__button {
          input.facebook-button {
            display: none;
          }
        }
      }
    }
  }
}

.gnav-my-account-v1 {
  overflow-y: auto;
  overflow-x: hidden;
  .facebook-button {
    color: $color-white;
    background-color: $color-btn-blue;
    border: none;
    height: 49px;
    width: 315px;
    letter-spacing: 0.25px;
    position: absolute;
    margin-#{$ldirection}: 0;
    cursor: pointer;
    display: none;
  }
  .signin_container {
    .social-login__button {
      @include swap_direction(padding, 0 0 30px 0);
      width: 315px;
      margin: 0 auto;
      .facebook-logo {
        display: none;
        img {
          margin-#{$ldirection}: 36px;
        }
      }
    }
    .social-login__email-opt-in {
      .social-login__email-opt-in-label {
        margin-top: 15px;
      }
    }
    .social-login__terms {
      margin-top: 15px;
      display: block;
    }
  }
}

.social-login {
  .social-login__email-opt-in {
    display: flex;
  }
  .social-login__button {
    position: relative;
    .facebook-logo {
      display: none;
      z-index: 9999;
      position: absolute;
      cursor: pointer;
      width: 100%;
      margin-top: 10px;
      text-align: center;
      @media #{$medium-up} {
        width: auto;
      }
      img {
        cursor: pointer;
        vertical-align: middle;
      }
      span {
        color: $color-white;
        padding-#{$ldirection}: 10px;
        font-size: 18px;
        cursor: pointer;
      }
    }
  }
}
/* Account Signin Page */
#signin {
  .social-login {
    &__container {
      padding-top: 10px;
    }
    .social-login__terms {
      display: block;
    }
    .social-login__email-opt-in {
      label {
        margin-top: 0;
      }
    }
  }
  #signin-block {
    h2.myaccount-hdr {
      float: none;
    }
    h2.myaccount-hdr + p {
      width: max-content;
      float: none;
    }
    .social-login {
      .social-login__container {
        .social-login__email-opt-in {
          .facebook_email_optin_input {
            width: 35px;
            margin: 0;
            height: 15px;
          }
        }
      }
    }
  }
  #main.signin_container {
    max-width: 900px;
    @media #{$large-med-up} {
      max-width: 1175px;
    }
    .signin_container.outer-wrap {
      margin: 0;
      max-width: 100%;
    }
    .social-login__email-opt-in-label {
      @media #{$medium-up} {
        margin-#{$ldirection}: 10px;
      }
    }
    .signin-section {
      width: 100%;
    }
    form {
      .form-item {
        &.privacy-form-container,
        &.email_promotions {
          span {
            width: 90%;
          }
        }
      }
    }
  }
  .block-container {
    width: 100%;
  }
}

#confirm {
  #confirmation-page {
    .social-login {
      .social-login__email-opt-in {
        margin-top: 30px;
        margin-bottom: 10px;
        .facebook_email_optin_input {
          width: 34px;
          margin-#{$rdirection}: 10px;
          @media #{$medium-up} {
            width: 18px;
          }
        }
      }
      .social-login__email-opt-in-label {
        margin-top: 0;
      }
      .social-login__button {
        .facebook-logo {
          display: none;
          @media #{$medium-up} {
            width: 320px;
          }
        }
      }
    }
  }
}

.signin-social-section {
  @media #{$medium-only} {
    padding: 0 20px;
  }
}

.social_login_v2 {
  &__delimeter {
    height: 10px;
    &-horizontal {
      height: 2px;
      margin-top: 20px;
      position: relative;
      @if $cr19 == true {
        background-color: $cr19-bg-black;
      } @else {
        background-color: $color-cl-green-2;
      }
    }
    &-label {
      padding: 0 6px;
      background-color: $color-white;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 6px);
      position: absolute;
      text-transform: uppercase;
      display: inline;
    }
  }
}

.social-login-container {
  display: flex;
  justify-content: center;
  &__social-network {
    width: 60px;
    height: 60px;
    display: block;
    margin: 22px;
    overflow: hidden;
    border: none;
    border-radius: 5px;
    padding: 0;
    position: relative;
    @media #{$medium-up} {
      width: 264px;
      height: 40px;
    }
    &-blocker {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      #{$ldirection}: 0;
      cursor: pointer;
      &_hidden {
        display: none;
      }
    }
    &-button {
      width: 100%;
      height: 100%;
      display: block;
      font: $base-font-size $helvetica-regular-font-family;
      letter-spacing: 0.25px;
      border: none;
      line-height: 40px;
      color: $color-white;
      &-label {
        display: none;
        padding-#{$ldirection}: 40px;
        text-align: center;
        width: 100%;
        @media #{$medium-up} {
          display: inline-block;
        }
      }
      &_facebook {
        background: $color-facebook url(/media/images/social_login/fb_icon.svg) 0 center no-repeat;
        background-size: 60px 60px;
        background-repeat: no-repeat;
        @media #{$medium-up} {
          background-size: 40px 40px;
        }
      }
      &_vkontakte {
        background: $color-vkontakte url(/media/images/social_login/vk_icon.svg) 10px center
          no-repeat;
        background-size: 40px 40px;
        background-repeat: no-repeat;
        @media #{$medium-up} {
          background-size: 30px 30px;
        }
      }
    }
  }
}

.sign-in-component_ru {
  &__legal-container {
    width: 100%;
    margin: auto;
    text-transform: none;
    @media #{Smedium-up} {
      width: 566px;
    }
    .legal-container__acceptance-block {
      padding-bottom: 10px;
      .form-checkbox {
        margin-top: 2px;
      }
      label.label {
        padding-#{$ldirection}: 10px;
        display: inline-block !important;
        max-width: 94%;
        vertical-align: top;
      }
    }
    .legal-container__acceptance-block_error * {
      color: $color-red !important;
    }
  }
}

.checkout {
  .social_login_v2__delimeter {
    clear: both;
  }
  .sign-in-component_ru__legal-container {
    width: 90%;
  }
}

#signin,
#main.single {
  #signin-block,
  #sign-in-panel {
    .sign-in-component_ru__legal-container {
      .legal-container__acceptance-block {
        .form-checkbox {
          width: auto;
          height: auto;
        }
      }
    }
  }
}

.social-info {
  &__content {
    .social-info__vk {
      margin-bottom: 20px;
      font-size: 9px;
      @media #{$medium-up} {
        font-size: 13px;
      }
      button {
        line-height: 20px;
      }
      .vkontakte-login {
        @include swap_direction(padding, 0 5px 0 30px);
        background: $color-vkontakte url(/media/images/social_login/vk_icon_21x21.png) 4px 0
          no-repeat;
        max-width: 95%;
        letter-spacing: 0.6px;
        font-size: 11px;
        border-radius: 3px;
        border: 0;
        color: $color-white;
        margin-#{$ldirection}: 88px;
        @media #{$medium-up} {
          margin-#{$ldirection}: 127px;
        }
      }
      #vkontakte_disconnect {
        display: inline-block;
        margin-#{$ldirection}: 77px;
        @media #{$medium-up} {
          margin-#{$ldirection}: 110px;
        }
        .button--dark {
          margin: 0;
          font-size: 12px;
          padding: 0 10px;
          height: 29px;
        }
      }
    }
  }
}

#profile_preferences {
  .pc_email_promo_container {
    display: flex;
    @media #{$medium-up} {
      display: block;
    }
  }
}
