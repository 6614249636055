.device-mobile {
  .store-locator-mobile-v1 {
    .map_container {
      width: auto;
      #map_canvas {
        width: auto;
        z-index: 9999;
        .gm-style {
          .gm-style-iw {
            position: absolute !important;
            left: 15px !important;
            top: 9px !important;
            max-width: 200px !important;
            min-width: 140px !important;
          }
        }
      }
    }
  }
  #top {
    #header {
      display: flex;
      .btn_navigation {
        position: initial;
        width: 10%;
      }
      .block-template-search-mobile-v1 {
        .gnav-search-mobile-v1 {
          .search-icon {
            img {
              top: 0;
            }
          }
        }
      }
      .block-template-site-logo-responsive-v1 {
        width: 75%;
        display: inline-block;
        text-align: center;
        position: relative;
        bottom: 5px;
        @media screen and (min-width: 320px) and (max-width: 750px) {
          bottom: 0;
          top: 2px;
        }
      }
      .block-template-gnav-utility-button-mobile-v1 {
        right: 20px;
        top: 9px;
        bottom: unset;
        @media screen and (max-width: 767px) {
          right: 45px;
        }
      }
      .block-template-gnav-cart-v1 {
        bottom: unset;
        top: 0;
        @media screen and (min-width: 320px) and (max-width: 750px) {
          right: 0;
        }
      }
    }
  }
}

.accordionItem.power-couples-row-mobile-v1 {
  .cta-button-wrapper {
    a.button {
      background-color: $color-cl-green-2 !important;
    }
  }
}

.accordionItem.three-step-shopbystep-mobile-v1 {
  h3 {
    max-height: 300px;
  }
}

.three-step-row-mobile-v1 {
  .product {
    .product-info {
      width: 65%;
    }
  }
}

#quickshop.module-spp-detail {
  .replenishment {
    display: none;
  }
}

.product-full__price {
  &-container {
    width: 125px;
    display: table-cell;
    &.product-full__price-has_size,
    .product-full__price-size-select-container & {
      min-width: 225px;
    }
  }
  &-description {
    display: table-cell;
    vertical-align: middle;
  }
  &-loyalty {
    display: table;
    margin: 10px 0;
    &.active {
      font-weight: bold;
    }
    &.tier3,
    &.tier2,
    &.tier1 {
      margin: 0;
    }
    &--link a {
      font-weight: normal;
    }
    &--header {
      padding-left: 15px;
    }
  }
  &-text {
    margin-right: 20px;
    font-size: inherit;
    display: block;
    margin: 0px;
    padding: 0px;
    border: none;
  }
}

#ff-content {
  #refinements {
    .dimensions {
      .skin_tone_container {
        .instruction_txt {
          width: 250px;
        }
        .skin_tone_txt {
          padding-top: 5px;
        }
      }
    }
  }
}

.page-products,
.page-product {
  #foreground-node {
    left: 0px !important;
    .close-container {
      position: absolute;
      right: 7px;
      top: 7px;
    }
    iframe {
      height: 260px;
    }
  }
}

@media screen and (max-width: 640px) and (min-width: 400px) {
  .nav-promo {
    .offer-promo-carousel-v1 {
      .offer-promo-mobile-v1 {
        .headline,
        .cta {
          font-size: 17px;
        }
      }
    }
  }
}

body.toolbar-drawer {
  padding-top: 0px !important;
}

.field-mobile-template {
  .mpp-box {
    .product-info {
      width: 55%;
      .product-name,
      .product-title {
        font-size: 19px;
        line-height: 1;
        margin: 30px 0 0;
      }
      .button-wrapper {
        .button {
          p {
            line-height: 2.3;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }
  }
  .squares-row-mobile-v1.item-2 {
    .squares-container {
      .square.last {
        min-height: 156px;
      }
    }
  }
}

.product-content-carousel-v1 {
  .pcg-product-mobile-v1 {
    .product {
      .product-image {
        img {
          @include breakpoint($xsmall-up) {
            width: 110%;
          }
        }
      }
    }
    &.pcg-product-image-title {
      .product-info,
      .product-title {
        font-size: 15px;
        line-height: normal;
        line-height: normal;
        letter-spacing: normal;
      }
    }
  }
  .loading-status {
    img {
      width: auto;
    }
  }
}

.mobile_mpp {
  #mpp-product-grid {
    .mpp_product {
      .product-info {
        .price {
          margin-top: 10px;
        }
        word-wrap: break-word;
        .button-wrapper {
          .add-to-cart {
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }
  }
}

#lhc_status_container {
  @media screen and (min-width: 320px) and (max-width: 750px) {
    .status-icon {
      display: none;
    }
  }
}

.device-mobile {
  #ship-calc-overlay {
    h2 {
      font-size: 29px;
    }
    .country-container {
      float: left;
      width: 100%;
      text-transform: uppercase;
      margin-bottom: 10px;
      input,
      select {
        width: 100%;
        float: left;
        text-transform: uppercase;
      }
    }
    .calculation-result {
      .shipping-type {
        font-weight: bold;
      }
    }
    .close-popup {
      padding: 10px 15px 7px 15px;
      margin-left: 15px;
      font-size: 17px;
      @if $cr19 == true {
        border: 1px solid $cr19-border-black;
        color: $cr19-text-black;
      } @else {
        border: 1px solid #5dba98;
        color: #5dba98;
      }
    }
    .btn-add-to-bag {
      padding: 0 7px;
    }
    .missing_region,
    .missing_city {
      color: $color-red !important;
    }
  }
  .shipping-cost-widget {
    .shipping-price {
      margin-right: 5px;
    }
    .shipping-title {
      font-weight: bold;
    }
  }
  .shipping-calc-results {
    padding: 10px 15px 0;
  }
  .shipping-calc-link {
    padding: 25px 15px 0px 14px;
  }
}

.device-mobile {
  #header {
    .btn_navigation {
      position: absolute;
      #{$ldirection}: 0;
    }
    .block-template-gnav-search-v1,
    .block-template-search-mobile-v1,
    .block-template-site-logo-responsive-v1,
    .block-template-gnav-cart-v1 {
      display: inline-block;
    }
    .block-template-gnav-utility-button-mobile-v1 {
      .gnav-utility-button-v1 {
        border-right: 0px !important;
      }
      display: inline-block;
      position: absolute;
      #{$rdirection}: 48px;
    }
    .block-template-gnav-cart-v1 {
      position: absolute;
      #{$rdirection}: 0px;
    }
    .block-template-search-mobile-v1 {
      position: absolute;
      #{$ldirection}: 47px;
    }
  }
}

@media screen and (max-width: 767px) and (orientation: landscape) {
  .device-mobile {
    #header {
      .block-template-gnav-cart-v1 {
        width: 7% !important;
      }
    }
  }
}

.device-mobile {
  .shipping-cost-widget {
    p {
      margin: 10px 0px 8px;
      width: 275px;
    }
    margin-bottom: -25px;
    .shipping-cost {
      margin: 1.5em 0;
    }
  }
  a.shipping_calc {
    text-decoration: underline;
  }
}

@media screen and (min-width: 767px) {
  .shipping-calc-results {
    .shipping-cost {
      .country-container {
        float: none !important;
        display: block;
      }
    }
  }
}

.shipping-calc-results {
  .shipping-cost {
    .country-container {
      float: left;
      padding-bottom: 10px;
      width: 325px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .page-products,
  .page-product {
    #foreground-node {
      height: auto !important;
    }
  }
}
//viewcart Icon Missing CL RU
.device-mobile {
  #header {
    .block-template-gnav-utility-button-mobile-v1,
    .block-template-gnav-cart-v1 {
      bottom: 10px;
    }
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  #ship-calc-overlay {
    .close-popup {
      margin-left: 0 !important;
    }
  }
}

.column-main {
  .field-mobile-template {
    div.mobile_mpp {
      div.mpp_toggle_view {
        margin: 0px;
      }
    }
  }
}

.my-account-favorites-mobile-v1 {
  #favorites-tab {
    .no-favorites {
      .no-favorites-text {
        padding-left: 10px;
        .button {
          width: 98%;
        }
      }
    }
  }
}

@media (max-width: 48em) {
  .spc_enabled {
    #main.single {
      .responsive-container {
        .column {
          .continue-shopping {
            width: 100%;
            margin: 12px 0 0 !important;
            height: 45px;
            line-height: 44px;
            max-width: none;
            font-size: 18px;
          }
        }
      }
    }
  }
}

body {
  height: auto !important;
  &.section-foundation-finder {
    .field-content {
      #foundation-finder-quiz {
        .refine-results {
          padding: 30px 0;
          &-expanded_view {
            li {
              &.ff-quiz {
                &__nav-skingoal {
                  &:last-child {
                    top: 85%;
                  }
                }
              }
            }
          }
        }
        .foundation-finder {
          &_form-selection {
            li {
              width: 340px;
            }
          }
          &_skin-goal-selection {
            text-align: left;
            li {
              font-size: 16px;
            }
          }
          &_finish-selection {
            width: 342px;
            .selection_skip {
              float: left;
            }
            li {
              width: 50% !important;
            }
          }
        }
        .skin-goal-two {
          .ff_slide_content {
            margin: -12px auto;
          }
        }
        .ff-quiz {
          &__subhead--bottom {
            width: 190px;
          }
          &__slide {
            height: 780px !important;
          }
        }
      }
    }
  }
}

.appt-book {
  .service-select {
    .service {
      .service-details {
        padding-left: 10px;
      }
    }
    .book-now-bar {
      .minutes {
        width: 40%;
      }
      .button {
        width: 60%;
        font-size: 18px;
        padding: 5px 0;
      }
    }
  }
  .appt-book-page-header {
    .appt-book-page-header-content {
      left: 10px;
      .appt-book-page-header-content__dots {
        display: none;
      }
      .appt-book-page-header-content__title {
        line-height: 0.025em;
        h4 {
          width: 60%;
        }
      }
      .appt-book-page-header-content__subhead {
        padding-top: 5px;
        p {
          font-size: 13px;
        }
      }
    }
  }
  .appointment-select {
    .appointments-container {
      .book-appt-container {
        .mobile-phone-fieldset {
          .appt-book-mobile-prefix {
            width: 20%;
            display: inline-block;
          }
          .appt-book-mobile {
            width: 80%;
            float: right;
          }
        }
        .registration__email-list,
        .registration__terms,
        .registration__sms-list {
          .registration__email-list-text {
            display: inline-block;
            width: 90%;
          }
        }
        .registration__terms.error {
          color: $color-notice-pink;
        }
      }
    }
  }
  .confirmation {
    .confirmation-container {
      .appt-date-cal {
        .add-cal-title,
        .add-cal-desc {
          display: none;
        }
        .cals-container.expanded {
          float: left;
        }
      }
    }
  }
  .appointment-container {
    .your-lessons,
    .appt-date {
      p {
        display: inline-block;
      }
    }
  }
}

.appt-book-overlay.my-appointments-overlay {
  .overlay-content-container {
    height: auto;
    .canceled {
      span p {
        display: inline;
        margin: 0;
      }
    }
  }
}

.tab-switcher {
  #offer-code-panel {
    .content {
      p {
        display: none;
      }
    }
  }
}

h1,
h2,
h3 {
  font-weight: 300;
}

.filter-by-skin-concern {
  position: relative;
  top: -100px;
}

.filter-by-skin-type {
  position: relative;
  top: -152px;
}

.mpp_toggle_view {
  margin-top: 0px !important;
}

.write_a_review__container {
  #pr-write {
    .p-w-r {
      .pr-media_image-form-group,
      .pr-media_videourl-form-group {
        display: block;
      }
    }
  }
}

.footer-top {
  .quickwins-checkout__footer {
    display: none;
  }
}

#header {
  .callcenter,
  .head-call_center {
    display: none;
  }
}
/* Phone mask */
#address_form_container {
  @include phone-masking;
  .intl-tel-input {
    .flag-dropdown {
      .selected-flag {
        @include swap_direction(padding, 5px 16px 6px 10px);
      }
    }
  }
}

//CL Randomizer
section.clinique-spinner {
  @include breakpoint($small-max) {
    p.clinique-spinner-mainline {
      font-size: 18px;
    }
  }
}

.random-gift-picker-formatter {
  #game-error,
  #game-unavailable {
    .pop-up-box-inner {
      p {
        &.small {
          line-height: 10px;
        }
      }
      @include breakpoint($small-max) {
        h2 {
          font-size: 20px;
        }
        .button {
          max-width: 245px;
          margin: 0;
        }
      }
    }
  }
  .win-box-btns {
    .button {
      padding: 0;
    }
  }
}

.invisible {
  &.focusable {
    display: none;
  }
}

.legal_checkbox_container {
  .terms_conditions {
    display: flex;
    input[type='checkbox'] {
      margin-#{$rdirection}: 8px;
    }
  }
}
