.elc-product-full {
  .elc-vto-foundation {
    .elc-vto {
      &-tab {
        white-space: normal;
      }
      &-perfect-shade-label {
        width: auto;
      }
      &-foundation-shade-grid {
        &.vto-foundation-lip {
          .elc-vto-product-name {
            font-weight: bold;
            height: 61px;
          }
        }
      }
      &-shade-info-right-container {
        text-align: $rdirection;
        width: 50%;
      }
      &-see-all-shades,
      &-see-perfect-shades {
        &.elc-button {
          [data-component] & {
            border: 0;
            font-size: 13px;
            margin-#{$ldirection}: 10px;
            text-decoration: underline;
          }
        }
      }
    }
    .elc-add-to-bag-button,
    .elc-product-notify-me-button {
      font-size: 14px;
      height: 48px;
      line-height: normal;
    }
  }
  .elc-price-formatted-wrapper {
    .elc-price-formatted {
      font-weight: bold;
    }
  }
}
